import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
    {
        path: '/(uk/|ru/)?/icons',
        name: 'allIcons',
        component: () => import('./views/allIcons'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-bill',
            title: 'icons'
        },
    }, {
        path: '*',
        component: () => import('./views/NotFound'),
        query: true,
        meta: {
            requiresAuth: true,
            title: '404'
        }
    }, {
        path: '/(uk/|ru/)?/',
        component: () => import('./views/dashboard/Dashboard'),
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-dashboard',
            title: 'dashboard'
        }
    }, {
        path: '/(uk/|ru/)?/dashboard',
        name: 'dashboard',
        component: () => import('./views/dashboard/Dashboard'),
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-dashboard',
            title: 'dashboard'
        }
    }, {
        path: '/(uk/|ru/)?/login',
        name: 'login',
        component: () => import('./views/login/Login'),
        beforeEnter: (to, from, next) => {
            window.temphref = location.pathname + location.search
            next();
        },
        meta: {
            title: 'Вход'
        }
    }, {
        path: '/(uk/|ru/)?/restore',
        name: 'restore',
        component: () => import('./views/login/Restore'),
        meta: {
            title: 'Восстановление пароля',
            requiresAuth: false,
        }
    }, {
        path: '/(uk/|ru/)?/candidates/index',
        name: 'Candidate',
        query: true,
        component: () => import('./views/drivers/Candidate/List'),
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-candidates',
            title: 'Кандидаты'
        },
    }, {
        path: '/(uk/|ru/)?/candidates/list/index',
        name: 'CandidateList',
        query: true,
        component: () => import('./views/drivers/Candidate/List'),
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-candidates',
            title: 'Кандидаты'
        },
    }, {
        path: '/(uk/|ru/)?/drivers/candidates/edit', 
        name: 'CandidateEdit',
        component: () => import('./views/drivers/Candidate/Edit'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-candidates',
            title: 'Редактирвоание кандидата'
        },
    }, {
        path: '/(uk/|ru/)?/drivers/candidates/view', 
        name: 'LeadView',
        component: () => import('./views/drivers/Candidate/LeadView'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-candidates',
            title: 'Просмотр кандидата'
        },
    }, {
        path: '/(uk/|ru/)?/drivers/default/create',
        name: 'DriverCreate',
        component: () => import('./views/drivers/default/DriverCreate'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-drive',
            title: 'Создание нового водителя'
        },
    }, {
        path: '/(uk/|ru/)?/drivers/index', 
        name: 'DriversList',
        component: () => import('./views/drivers/default/List'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-drive',
            title: 'Водители'
        },
    }, {
        path: '/(uk/|ru/)?/offers/index', 
        name: 'offers',
        component: () => import('./views/drivers/oferts/index.vue'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-drive',
            title: 'Оферты'
        },
    }, {
        path: '/(uk/|ru/)?/work-rule/index', 
        name: 'Workrule',
        component: () => import('./views/drivers/workrule/WorkRule'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-drive',
            title: 'Условия работы'
        },
    }, {
        path: '/(uk/|ru/)?/orders/list', 
        name: 'Orders',
        component: () => import('./views/order/List'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-drive',
            title: 'Заказы'
        },
    }, {
        
        path: '/(uk/|ru/)?/cars/list', 
        name: 'CarsList',
        component: () => import('./views/drivers/default/CarsList'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-drive',
            title: 'Автомобили'
        },
    }, {
        
        path: '/(uk/|ru/)?/block-change-number/index', 
        name: 'BlockedMain',
        component: () => import('./views/drivers/Block/Index'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-drive',
            title: 'Блокировка при смене водителя'
        },
        children: [ {
            path: '',
            name: 'BlockedNew',
            component: () => import('./views/drivers/Block/tables/new'),
            query: true,
            props: true,
            meta: {
                requiresAuth: true,
                activeSideIcon:'#icon-drive',
                title: 'Блокировка при смене водителя'
            },
        }, {
            path: '/(uk/|ru/)?/block-change-number/verify',
            name: 'BlockedVerify',
            component: () => import('./views/drivers/Block/tables/verify'),
            query: true,
            props: true,
            meta: {
                requiresAuth: true,
                activeSideIcon:'#icon-drive',
                title: 'Блокировка при смене водителя'
            },
        }, {
            path: '/(uk/|ru/)?/block-change-number/blocked',
            name: 'BlockedBlocked',
            component: () => import('./views/drivers/Block/tables/blocked'),
            query: true,
            props: true,
            meta: {
                requiresAuth: true,
                activeSideIcon:'#icon-drive',
                title: 'Блокировка при смене водителя'
            },
        },]
    }, {
        path: '/(uk/|ru/)?/drivers/default/detail',
        component: () => import('./views/drivers/default/DriverEdit'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-drive',
            altNavSubMenuActive:'/drivers/index',
        },
        children: [
            {
                path: '',
                name: 'DriverDetail',
                component: () => import('./views/drivers/default/modules/Detail'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-drive',
                    title: 'Редактирование водителя'
                },
            }, {
                path: '/(uk/|ru/)?/drivers/default/detail/car',
                name: 'AutoDetail',
                component: () => import('./views/drivers/default/modules/Auto'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-drive',
                    title: 'Редактирование водителя'
                },
            }, {
                path: '/(uk/|ru/)?/drivers/default/detail/transactions',
                name: 'TransactionsDetails',
                component: () => import('./views/drivers/default/modules/TransactionsDetails'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-drive',
                    title: 'Редактирование водителя'
                },
            }, {
                path: '/(uk/|ru/)?/drivers/default/detail/monitoring',
                name: 'MonitoringIncomePayment',
                component: () => import('./views/drivers/default/modules/Monitoring'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-drive',
                    title: 'Редактирование водителя'
                },
            }, {
                path: '/(uk/|ru/)?/drivers/default/detail/bonus-details',
                name: 'BonusDetails',
                component: () => import('./views/drivers/default/modules/BonusDetails'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-drive',
                    title: 'Редактирование водителя'
                },
            }, {
                path: '/(uk/|ru/)?/drivers/default/detail/promo-raffle',
                name: 'PromoRaffle',
                component: () => import('./views/drivers/default/modules/PromoRaffle'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-drive',
                    title: 'Купоны розыгрыша'
                },
            }, {
                path: '/(uk/|ru/)?/drivers/default/detail/payments',
                name: 'PaymentsList',
                component: () => import('./views/drivers/default/modules/PaymentsList'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-drive',
                    title: 'Редактирование водителя'
                },
            }, {
                path: '/(uk/|ru/)?/drivers/default/detail/account',
                name: 'AccountControl',
                component: () => import('./views/drivers/default/modules/AccountControl'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-drive',
                    title: 'Редактирование водителя'
                },
            }, {
                path: '/(uk/|ru/)?/drivers/default/detail/cards',
                name: 'DriverCardControl',
                component: () => import('./views/drivers/default/modules/DriverCardControl'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-drive',
                    title: 'Редактирование водителя'
                },
            }, {
                path: '/(uk/|ru/)?/drivers/default/detail/history',
                name: 'History',
                component: () => import('./views/drivers/default/modules/History'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-drive',
                    title: 'Редактирование водителя'
                },
            }, {
                path: '/(uk/|ru/)?/drivers/default/detail/order',
                name: 'Order',
                component: () => import('./views/drivers/default/modules/Order'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-drive',
                    title: 'Редактирование водителя'
                },
            }, {
                path: '/(uk/|ru/)?/drivers/default/detail/statement',
                name: 'Statement',
                component: () => import('./views/drivers/default/modules/Statement'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-drive'
                },
            }, {
                path: '/(uk/|ru/)?/drivers/default/detail/AutoPay',
                name: 'AutoPay',
                component: () => import('./views/drivers/default/modules/AutopayTable'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-drive',
                    title: 'Редактирование водителя'
                },
            }, {
                path: '/(uk/|ru/)?/drivers/default/detail/topup',
                name: 'TopUp',
                component: () => import('./views/drivers/default/modules/TopUp'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-drive',
                    title: 'Редактирование водителя'
                },
            }, {
                path: '',
                name: 'PromoCodeTabs',
                component: () => import('./views/drivers/default/modules/PromoCodeTabs'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-drive',
                    title: 'Редактирование водителя'
                },
                children: [
                    {
                        path: '/(uk/|ru/)?/drivers/default/detail/promo/active',
                        name: 'PromoCode',
                        component: () => import('./views/drivers/default/modules/PromoCode'),
                        query: true,
                        meta: {
                            requiresAuth: true,
                            activeSideIcon:'#icon-drive',
                            title: 'Редактирование водителя'
                        }
                    }, {
                        path: '/(uk/|ru/)?/drivers/default/detail/promo/new',
                        name: 'PromoCodeNew',
                        component: () => import('./views/drivers/default/modules/PromoCodeNew'),
                        query: true,
                        meta: {
                            requiresAuth: true,
                            activeSideIcon:'#icon-drive',
                            title: 'Редактирование водителя'
                        }
                    }
                ]
            }, {
                path: '',
                name: 'PromoActiveList',
                component: () => import('./views/drivers/default/modules/PromoInfo'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-drive',
                    title: 'Редактирование водителя'
                },
                children: [
                    {
                        path: '/(uk/|ru/)?/drivers/default/detail/promo',
                        name: 'PromoInfo',
                        component: () => import('./views/drivers/default/modules/PromoActiveList'),
                        query: true,
                        meta: {
                            requiresAuth: true,
                            activeSideIcon:'#icon-drive',
                            title: 'Редактирование водителя'
                        }
                    }
                ]
            }, {
                path: '/(uk/|ru/)?/drivers/default/detail/referral',
                name: 'Referral',
                component: () => import('./views/drivers/default/modules/Referral'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-drive',
                    title: 'Редактирование водителя'
                },
            }, {
                path: '/(uk/|ru/)?/drivers/default/detail/log-payment',
                name: 'LogPayment',
                component: () => import('./views/drivers/default/modules/LogPayment'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-drive',
                    title: 'Редактирование водителя'
                },
            }, {
                path: '/(uk/|ru/)?/drivers/default/detail/call-history',
                name: 'CallHistory',
                component: () => import('./views/drivers/default/modules/Callhistory'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-drive',
                    title: 'Редактирование водителя'
                },
            },
        ]
    }, {
        path: '/(uk/|ru/)?/dashboard/company-manangment', 
        redirect:'/(uk/|ru/)?/dashboard/company-manangment/park',
        name: 'Company',
        component: () => import('./views/cabinet/company/Company'),
        query: true,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/(uk/|ru/)?/dashboard/company-manangment/park',
                name: 'CompanyPark',
                component: () => import('./views/cabinet/company/tabs/CompanyPark'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Управление компанией'
                },
            }, {
                path: '/(uk/|ru/)?/dashboard/company-manangment/role',
                name: 'CompanyRole',
                component: () => import('./views/cabinet/company/tabs/CompanyRole'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Управление компанией'
                },
            }, {
                path: '/(uk/|ru/)?/dashboard/company-manangment/user',
                name: 'CompanyUser',
                component: () => import('./views/cabinet/company/tabs/CompanyUser'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Управление компанией'
                },
            }, {
                path: '/(uk/|ru/)?/dashboard/company-manangment/department',
                name: 'CompanyDepartment',
                component: () => import('./views/cabinet/company/tabs/CompanyDepartment'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Управление компанией'
                },
            }, {
                path: '/(uk/|ru/)?/dashboard/company-manangment/provider',
                name: 'CompanyPaymentProvider',
                component: () => import('./views/cabinet/company/tabs/CompanyPaymentProvider'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Управление компанией'
                },
            }, {
                path: '/(uk/|ru/)?/dashboard/company-manangment/source-payment-provider',
                name: 'CompanySourcePaymentProvider',
                component: () => import('./views/cabinet/company/tabs/CompanySourcePaymentProvider'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Управление компанией'
                },
            }, {
                path: '/(uk/|ru/)?/dashboard/company-manangment/payment-config-and-commission',
                name: 'CompanyPaymentConfigAndCommission',
                component: () => import('./views/cabinet/company/tabs/CompanyPaymentConfigAndCommission'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Управление компанией'
                },
            }, {
                path: '/(uk/|ru/)?/dashboard/company-manangment/provider-service',
                name: 'CompanyProviderService',
                component: () => import('./views/cabinet/company/tabs/CompanyProviderService'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Управление компанией'
                },
            }, {
                path: '/(uk/|ru/)?/dashboard/company-manangment/chat-platform', 
                name: 'CompanyChatPlatform',
                component: () => import('./views/cabinet/company/tabs/CompanyChatPlatform'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Управление компанией'
                },
            }, {
                path: '/(uk/|ru/)?/dashboard/company-manangment/application-instance', 
                name: 'CompanyApplicationInstance',
                component: () => import('./views/cabinet/company/tabs/CompanyApplicationInstance'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Управление компанией'
                },
            }, {
                path: '/(uk/|ru/)?/dashboard/company-manangment/application-setting', 
                // name: 'CompanyApplicationManagement',
                component: () => import('./views/cabinet/company/tabs/CompanyApplicationManagement'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Управление компанией'
                },
                children: [{
                    path: '', 
                    name: 'CompanyApplicationManagementGeneral',
                    component: () => import('./views/cabinet/company/tabs/CompanyApplicationManagement/General.vue'),
                    query: true,
                    meta: {
                        requiresAuth: true,
                        title: 'Настройка приложения'
                    },
                }, {
                    path: '/(uk/|ru/)?/dashboard/company-manangment/application-setting/revision', 
                    name: 'CompanyApplicationManagementRevision',
                    component: () => import('./views/cabinet/company/tabs/CompanyApplicationManagement/Revision.vue'),
                    query: true,
                    meta: {
                        requiresAuth: true,
                        title: 'Настройка приложения'
                    },
                    
                }, {
                    path: '/(uk/|ru/)?/dashboard/company-manangment/application-setting/revision/form', 
                    name: 'CompanyApplicationManagementRevisionForm',
                    component: () => import('./views/cabinet/company/tabs/CompanyApplicationManagement/RevisionForm.vue'),
                    query: true,
                    meta: {
                        requiresAuth: true,
                        title: 'Настройка приложения'
                    }, 
                    
                },  {
                    path: '/(uk/|ru/)?/dashboard/company-manangment/application-setting/oferta', 
                    name: 'CompanyApplicationManagementRevisionOferta',
                    component: () => import('./views/cabinet/company/tabs/CompanyApplicationManagement/Oferta.vue'),
                    query: true,
                    meta: {
                        requiresAuth: true,
                        title: 'Настройка приложения'
                    },    
                },  {
                    path: '/(uk/|ru/)?/dashboard/company-manangment/application-setting/translate', 
                    name: 'CompanyApplicationManagementRevisionTranslate',
                    component: () => import('./views/cabinet/company/tabs/CompanyApplicationManagement/Translate.vue'),
                    query: true,
                    meta: {
                        requiresAuth: true,
                        title: 'Настройка приложения'
                    },    
                }, {
                    path: '/(uk/|ru/)?/dashboard/company-manangment/application-setting/agreement', 
                    name: 'CompanyApplicationManagementAgreementtie',
                    component: () => import('./views/cabinet/company/tabs/CompanyApplicationManagement/Agreement.vue'),
                    query: true,
                    meta: {
                        requiresAuth: true,
                        title: 'Настройка приложения'
                    },    
                },]
            }, {
                path: '/(uk/|ru/)?/dashboard/company-manangment/team-settings', 
                component: () => import('./views/cabinet/company/tabs/CompanyTeamSetting'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Настройка Бригад'
                },
                children: [{
                    path: '', 
                    name: 'CompanyTeamSettingworkRule',
                    component: () => import('./views/cabinet/company/tabs/CompanyTeamSetting/WorkRule.vue'),
                    query: true,
                    meta: {
                        requiresAuth: true,
                        title: 'Условия работы Бригад'
                    },
                }, {
                    path: '/(uk/|ru/)?/dashboard/company-manangment/team-settings/settings', 
                    name: 'CompanyTeamSetting',
                    component: () => import('./views/cabinet/company/tabs/CompanyTeamSetting/Settings.vue'),
                    query: true,
                    meta: {
                        requiresAuth: true,
                        title: 'Настройка Бригад'
                    },
                },]
            }, {
                path: '/(uk/|ru/)?/dashboard/company-manangment/overdraft', 
                name: 'CompanyOverdraft',
                component: () => import('./views/cabinet/company/tabs/CompanyOverdraft'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Офердрафт'
                },
              
            }, {
                path: '/(uk/|ru/)?/dashboard/company-manangment/spands', 
                name: 'CompanySpends',
                component: () => import('./views/cabinet/company/tabs/CompanySpends'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Управление компанией'
                },
            },]
    }, {
        path: '/(uk/|ru/)?/dashboard/company-manangment/team-settings/edit', 
        name: 'FormTeamSetting',
        component: () => import('./views/cabinet/company/form/TeamSetting'),
        query: true,
        meta: {
            requiresAuth: true,
            title: 'Управление компанией'
        },
    }, {
        path: '/(uk/|ru/)?/overdraft', 
        name: 'Overdraft',
        component: () => import('./views/overdraft/Overdraft'),
        query: true,
        meta: {
            requiresAuth: true,
            title: 'Овердрафт'
        },
        children: [{
            path: '', 
            name: 'overdraft-new',
            component: () => import('./views/overdraft/tables/new'),
            query: true,
            meta: {
                requiresAuth: true,
                title: 'Овердрафт'
            },
        }, {
            path: '/(uk/|ru/)?/overdraft/active', 
            name: 'overdraft-active',
            component: () => import('./views/overdraft/tables/active'),
            query: true,
            meta: {
                requiresAuth: true,
                title: 'Овердрафт'
            },
        }, {
            path: '/(uk/|ru/)?/overdraft/rejected', 
            name: 'overdraft-rejected',
            component: () => import('./views/overdraft/tables/rejected'),
            query: true,
            meta: {
                requiresAuth: true,
                title: 'Овердрафт'
            },
        },  {
            path: '/(uk/|ru/)?/overdraft/closed', 
            name: 'overdraft-closed',
            component: () => import('./views/overdraft/tables/closed'),
            query: true,
            meta: {
                requiresAuth: true,
                title: 'Овердрафт'
            },
        }]
    }, {
        path: '/(uk/|ru/)?/dashboard/company-manangment/park/edit', 
        name: 'CompanyParkEdit',
        component: () => import('./views/cabinet/company/form/Park'),
        query: true,
        meta: {
            requiresAuth: true,
            title: 'Парк'
        },
    }, {
        path: '/(uk/|ru/)?/dashboard/company-manangment/role/edit', 
        name: 'CompanyRoleEdit',
        component: () => import('./views/cabinet/company/form/Role'),
        query: true,
        meta: {
            requiresAuth: true,
            title: 'Управление компанией'
        },
    }, {
        path: '/(uk/|ru/)?/dashboard/company-manangment/user/edit', 
        name: 'CompanyUserEdit',
        component: () => import('./views/cabinet/company/form/User'),
        query: true,
        meta: {
            requiresAuth: true,
            title: 'Управление компанией'
        },
    },
    //   {
    //     path: '/(uk/|ru/)?/dashboard/company-manangment/user/change-pass', 
    //     name: 'CompanyUserChangePass',
    //     component: () => import('./views/cabinet/company/form/ChangePass'),
    //     query: true,
    //     meta: {
    //         requiresAuth: true,
    //         title: 'Управление компанией'
    //     },
    // },
     {
        path: '/(uk/|ru/)?/dashboard/company-manangment/provider-service/edit',
        name: 'ServiceProviderEdit',
        component: () => import('./views/cabinet/company/form/ServiceProvider'),
        query: true,
        meta: {
            requiresAuth: true,
            title: 'Управление компанией'
        },
    }, {
        path: '/(uk/|ru/)?/dashboard/company-manangment/department/edit', 
        name: 'CompanyDepartmentEdit',
        component: () => import('./views/cabinet/company/form/Department'),
        query: true,
        meta: {
            requiresAuth: true,
            title: 'Управление компанией'
        },
    }, {
        path: '/(uk/|ru/)?/dashboard/company-manangment/provider/edit',
        name: 'PaymentProvider',
        component: () => import('./views/cabinet/company/form/PaymentProvider'),
        query: true,
        meta: {
            requiresAuth: true,
            title: 'Управление компанией'
        },
    }, {
        path: '/(uk/|ru/)?/dashboard/company-manangment/source-payment-provider/edit', 
        name: 'SourcePaymentProvider',
        component: () => import('./views/cabinet/company/form/SourcePaymentProvider'),
        query: true,
        meta: {
            requiresAuth: true,
            title: 'Управление компанией'
        },
    }, {
        path: '/(uk/|ru/)?/dashboard/company-manangment/chat-platform/edit', 
        name: 'ChatPlatform',
        component: () => import('./views/cabinet/company/form/ChatPlatform'),
        query: true,
        meta: {
            requiresAuth: true,
            title: 'Управление компанией'
        },
    }, {
        path: '/(uk/|ru/)?/dashboard/company-manangment/application-instance/edit', 
        name: 'ApplicationInstanceform',
        component: () => import('./views/cabinet/company/form/ApplicationInstanceform'),
        query: true,
        meta: {
            requiresAuth: true,
            title: 'Управление компанией'
        },
    }, {
        path: '/(uk/|ru/)?/dashboard/cabinet', 
        name: 'Personal',
        component: () => import('./views/cabinet/personal/Personal'),
        query: true,
        meta: {
            requiresAuth: true,
            title: 'Персональный кабинет'
        },
    }, {
        path: '', 
        name: 'CallCenter',
        component: () => import('./views/cabinet/callcenter/CallCenter'),
        query: true,
        meta: {
            requiresAuth: true,
            title: 'Управление кол центром'
        },
        children: [
            {
                path: '/(uk/|ru/)?/dashboard/cabinet/call-centger',
                name: 'PhrazeCategoriesList',
                component: () => import('./views/cabinet/callcenter/tabs/PhrazeCategoriesList'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Управление кол центром'
                },
            }, {
                path: '/(uk/|ru/)?/dashboard/cabinet/call-centger/statistic',
                name: 'StatisticList',
                component: () => import('./views/cabinet/callcenter/tabs/StatisticList'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Управление кол центром'
                },
            }, 
        ]
    }, {
        path: '', 
        name: 'CabinetReports',
        component: () => import('./views/cabinet/reports/Main'),
        query: true,
        meta: {
            requiresAuth: true,
            title: 'Отчеты'
        },
        children: [
            {
                path: '/(uk/|ru/)?/dashboard/cabinet/reports',
                name: 'CabinetReportsDriverList',
                component: () => import('./views/cabinet/reports/tabs/Drivers'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Отчеты: Водители'
                },
            }, {
                path: '/(uk/|ru/)?/dashboard/cabinet/reports/detail',
                name: 'CabinetReportsDetail',
                component: () => import('./views/cabinet/reports/tabs/Detail'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Отчеты: детальнее водители'
                },
            }, {
                path: '/(uk/|ru/)?/dashboard/cabinet/reports/chats',
                // name: 'CabinetReportsChats',
                component: () => import('./views/cabinet/reports/tabs/Chats'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Отчеты: чаты'
                },
                children: [
                    {
                      path: '',
                      name: 'ChatsGeneral',
                      component: () => import('./views/cabinet/reports/tabs/chats/ChatsGeneral'),
                      query: true,
                      meta: {
                          requiresAuth: true,
                          title: 'Отчеты: чаты Общее'
                      },
                    }, {
                        path: '/(uk/|ru/)?/dashboard/cabinet/reports/chats/category',
                        name: 'ChatsCategory',
                        component: () => import('./views/cabinet/reports/tabs/chats/ChatsCategory'),
                        query: true,
                        meta: {
                            requiresAuth: true,
                            title: 'Отчеты: чаты Категории'
                        },
                    }, {
                        path: '/(uk/|ru/)?/dashboard/cabinet/reports/chats/department',
                        name: 'ChatsDepartment',
                        component: () => import('./views/cabinet/reports/tabs/chats/ChatsDepartment'),
                        query: true,
                        meta: {
                            requiresAuth: true,
                            title: 'Отчеты: чаты Департаменты'
                        },
                    }  
                ]
            }, {
                path: '/(uk/|ru/)?/dashboard/cabinet/reports/chats/detail',
                name: 'CabinetReportsChatsDetail',
                component: () => import('./views/cabinet/reports/tabs/chats/ChatsDetail'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Отчеты: чаты'
                },
            }, {
                path: '/(uk/|ru/)?/dashboard/cabinet/reports/leeds',
                // name: 'CabinetReportsLeeds',
                component: () => import('./views/cabinet/reports/tabs/Leeds'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Отчеты: Лиды'
                },
                children: [
                    {
                        path: '',
                        name: 'LeedsGeneral',
                        component: () => import('./views/cabinet/reports/tabs/leeds/LeedsGeneral'),
                        query: true,
                        meta: {
                            requiresAuth: true,
                            title: 'Отчеты: Лиды'
                        },
                    },  {
                        path: '/(uk/|ru/)?/dashboard/cabinet/reports/leeds/source',
                        name: 'LeedsSource',
                        component: () => import('./views/cabinet/reports/tabs/leeds/LeedsSource'),
                        query: true,
                        meta: {
                            requiresAuth: true,
                            title: 'Отчеты: Лиды Источники'
                        },
                    },
                ]
            }, {
                path: '/(uk/|ru/)?/dashboard/cabinet/reports/leeds/detail',
                name: 'CabinetReportsLeedsDetail',
                component: () => import('./views/cabinet/reports/tabs/leeds/LeedsDetail'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Отчеты: Лиды Детализация'
                },
            }, {
                path: '/(uk/|ru/)?/dashboard/cabinet/reports/leeds/source/detail',
                name: 'CabinetReportsLeedsSourceDetail',
                component: () => import('./views/cabinet/reports/tabs/leeds/LeedsSourceDetail'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Отчеты: Лиды Детализация'
                },
            }, {
                path: '/(uk/|ru/)?/dashboard/cabinet/reports/calls',
                // name: 'CabinetReportsCalls',
                component: () => import('./views/cabinet/reports/tabs/Calls'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Отчеты: Звонки'
                },
                children: [
                    {
                        path: '',
                        name: 'CallsGeneral',
                        component: () => import('./views/cabinet/reports/tabs/calls/CallsGeneral'),
                        query: true,
                        meta: {
                            requiresAuth: true,
                            title: 'Отчеты: Звонки'
                        },
                    }, {
                        path: '/(uk/|ru/)?/dashboard/cabinet/reports/calls/departments',
                        name: 'Callsdepartments',
                        component: () => import('./views/cabinet/reports/tabs/calls/CallsDepartments'),
                        query: true,
                        meta: {
                            requiresAuth: true,
                            title: 'Отчеты: Звонки'
                        },
                    }, {
                        path: '/(uk/|ru/)?/dashboard/cabinet/reports/calls/category',
                        name: 'CallsCategory',
                        component: () => import('./views/cabinet/reports/tabs/calls/CallsCategory'),
                        query: true,
                        meta: {
                            requiresAuth: true,
                            title: 'Отчеты: Звонки'
                        },
                    },
                ]
            }, {
                path: '/(uk/|ru/)?/dashboard/cabinet/reports/calls/detail',
                name: 'CabinetReportsCallsDetail',
                component: () => import('./views/cabinet/reports/tabs/calls/CallsDetail'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Отчеты: Звонки Детализация'
                },
            }, {
                path: '/(uk/|ru/)?/dashboard/cabinet/reports/logs',
                name: 'CabinetReportsLogs',
                component: () => import('./views/cabinet/reports/tabs/Logs'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Отчеты: Лог действий'
                },
            },  {
                path: '/(uk/|ru/)?/dashboard/cabinet/reports/apps',
                name: 'CabinetReportsApp',
                component: () => import('./views/cabinet/reports/tabs/apps'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Отчеты: Лог действий'
                },
            }
        ]
    }, {
        path: '', 
        name: 'CabinetPayments',
        component: () => import('./views/cabinet/payments/System'),
        query: true,
        meta: {
            requiresAuth: true,
            title: 'Вывод средств'
        },
        children: [
            {
                path: '/(uk/|ru/)?/dashboard/cabinet/payments',
                name: 'CabinetPaymentsList',
                component: () => import('./views/cabinet/payments/tabs/List'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Вывод средств'
                },
            },  {
                path: '/(uk/|ru/)?/dashboard/cabinet/payments/cards',
                name: 'CabinetPaymentsCardList',
                component: () => import('./views/cabinet/payments/tabs/CabinetPaymentsCardList'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Вывод средств'
                },
            },
        ]
    }, {
        path: '/(uk/|ru/)?/dashboard/cabinet/payments/create', 
        name: 'PaymentCreate',
        component: () => import('./views/cabinet/payments/tabs/PaymentCreate'),
        query: true,
        meta: {
            requiresAuth: true,
            title: 'Вывод средств'
        },
    }, {
        path: '/(uk/|ru/)?/dashboard/cabinet/payments/cards/create', 
        name: 'PaymentCardsCreate',
        component: () => import('./views/cabinet/payments/tabs/PaymentCardsCreate'),
        query: true,
        meta: {
            requiresAuth: true,
            title: 'Вывод средств'
        },
    }, {
        path: '/(uk/|ru/)?/dashboard/cabinet/call-centger/fast-Phraze',
        name: 'AddFastPhraze',
        component: () => import('./views/cabinet/callcenter/tabs/AddFastPhraze'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-transaction',
            title: 'Новая быстрая фраза'
        },
    }, {
        path: '/(uk/|ru/)?/payments/index',
        name: 'bill',
        query: true,
        component: () => import('./views/payments/bill'),
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-bill',
            title: 'История вывода средств'
        },
        children: [
            // {
            //     path: '',
            //     name: 'bill-active',
            //     component: () => import('./views/payments/modules/billActive'),
            //     query: true,
            //     meta: {
            //         requiresAuth: true,
            //         title: 'Вывод средств'
            //     },
            // },  
            {
                // path: '/(uk/|ru/)?/payments/archive',
                path: '',
                name: 'bill-archive',
                component: () => import('./views/payments/modules/billArchive'),
                query: true,
                meta: {
                    requiresAuth: true,
                    title: 'Вывод средств'
                },
            },
        ]

    }, {
        path: '/(uk/|ru/)?/terminals/report',
        name: 'TerminalReport',
        query: true,
        component: () => import('./views/terminals/Report'),
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-terminal',
            title: 'Отчетность терминалов'
        },
    }, {
        path: '/(uk/|ru/)?/terminals/config',
        name: 'TerminalSettings',
        query: true,
        component: () => import('./views/terminals/Config'),
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-terminal',
            title: 'Настройка терминалов'
        },
    }, {
        path: '/(uk/|ru/)?/terminals/create',
        name: 'TerminalCreate',
        query: true,
        component: () => import('./views/terminals/Create'),
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-terminal',
            title: 'Управление терминалами'
        },
    }, {
        path: '/(uk/|ru/)?/cards/index',
        name: 'PayCards',
        component: () => import('./views/cards/PayCards'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-card',
            title: 'Карты для Выплат'
        },
    }, {
        path: '/(uk/|ru/)?/cards/detail',
        name: 'Details',
        component: () => import('./views/cards/Details'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-card',
            title: 'Дитализация'
        },
    }, {
        path: '/(uk/|ru/)?/fraudulent/index',
        name: 'Fraudulent',
        component: () => import('./views/cards/Fraudulent'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-card',
            title: 'Мошеннические карты'
        },
    }, {
        path: '/(uk/|ru/)?/queue/index',
        name: 'QueuePayments',
        component: () => import('./views/payments/QueuePayments'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-bill',
            title: 'Очередь платежей'
        },
    }, {
        path: '/(uk/|ru/)?/hold-funds/index',
        component: () => import('./views/payments/HoldFunds'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-bill',
            title: 'Очередь платежей'
        },
        children: [
            {
                path: '',
                name: 'QueuePaymentsActive', // Имя дочернего маршрута по умолчанию
                component: () => import('./views/payments/modules/hfActive'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-bill',
                    title: 'Очередь платежей'
                },
            }, {
                path: '/(uk/|ru/)?/hold-funds/inactive',
                name: 'QueuePaymentsinActive',
                component: () => import('./views/payments/modules/hfInActive'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-bill',
                    title: 'Очередь платежей'
                },
            }
        ]
    }, {
        path: '/(uk/|ru/)?/provider/index',
        component: () => import('./views/payments/Provider'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-bill',
            title: 'Движение средств по счетам провайдеров'
        },
    }, {
        path: '/(uk/|ru/)?/payments/log-error-payments',
        component: () => import('./views/payments/Error'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-bill',
            title: 'Отчет ошибок платежей'
        },
    }, {
        path: '/(uk/|ru/)?/scheduled/list',
        component: () => import('./views/payments/Scheduled'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-bill',
            title: 'Запланированные платежи'
        },
    }, {
        path: '/(uk/|ru/)?/transactions/index',
        name: 'Transactions',
        component: () => import('./views/transactions/Transactions'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-transaction',
            title: 'Ручные транзакции'
        },
    }, {
        path: '/(uk/|ru/)?/transactions/mass',
        name: 'Mass',
        component: () => import('./views/transactions/Mass'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-transaction',
            title: 'Массовые транзакции'
        },
    }, {
        path: '/(uk/|ru/)?/transactions/mass/detail',
        redirect: '/(uk/|ru/)?/transactions/mass/detail/info',
        name: 'FormMass',
        component: () => import('./views/transactions/form/FormMass'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-transaction',
        },
        children: [
            {
                path: '/(uk/|ru/)?/transactions/mass/detail/info',
                name: 'MainInfo',
                component: () => import('./views/transactions/form/MainInfo'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-transaction',
                    title: 'Новая массовая транзакция'
                },
            },  {
                path: '/(uk/|ru/)?/transactions/mass/detail/import',
                name: 'Import',
                component: () => import('./views/transactions/form/Import'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-transaction',
                    title: 'Новая массовая транзакция'
                },
            },  {
                path: '/(uk/|ru/)?/transactions/mass/detail/drivers',
                name: 'Drivers',
                component: () => import('./views/transactions/form/Drivers'),
                query: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-transaction',
                    title: 'Новая массовая транзакция'
                },
            }
        ]
    }, {
        path: '',
        component: () => import('./views/transactions/Statements'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-transaction',
            title: 'Ведомость'
        }, 
        children: [
            {
                path: '/(uk/|ru/)?/transactions/statements',
                name: 'StatementsCommon',
                component: () => import('./views/transactions/tables/Common'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-transaction',
                    title: 'Ведомость'
                },
            }, {
                path: '/(uk/|ru/)?/transactions/statements/parks',
                name: 'StatementsParks',
                component: () => import('./views/transactions/tables/Parks'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-transaction',
                    title: 'Ведомость'
                },
            }, {
                path: '/(uk/|ru/)?/transactions/income',
                name: 'Income',
                component: () => import('./views/transactions/Income'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-transaction',
                    title: 'Отчеты'
                },
            }, {
                path: '',
                name: 'StatementsDrivers',
                component: () => import('./views/transactions/tables/Drivers'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-transaction',
                    title: 'Ведомость'
                },
            }, 
        ]
    }, {
        path: '/(uk/|ru/)?/banner/index',
        name: 'BannerTable',
        component: () => import('./views/banner/BannerTable'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-discount',
            title:'Баннера'
        },
    }, {
        path: '/(uk/|ru/)?/banner/detail',
        name: 'BannerCreate',
        component: () => import('./views/banner/BannerCreate'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-discount',
            title: 'Создание нового банера'
        },
    }, {
        path: '/(uk/|ru/)?/anti-fraud/index',
        component: () => import('./views/antifraud/AntifraudList'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-cancel',
            title: 'Антифрод'
        },
        children: [
            {
                path: '',
                name: 'new',
                component: () => import('./views/antifraud/tables/new'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-cancel',
                    title: 'Антифрод'
                },
            }, {
                path: '/(uk/|ru/)?/anti-fraud/verify',
                name: 'verify',
                component: () => import('./views/antifraud/tables/verify'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-cancel',
                    title: 'Антифрод'
                },
            }, {
                path: '/(uk/|ru/)?/anti-fraud/blocked',
                name: 'blocked',
                component: () => import('./views/antifraud/tables/blocked'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-cancel',
                    title: 'Антифрод'
                },
            }, {
                path: '/(uk/|ru/)?/anti-fraud/black-list',
                name: 'BlackList',
                component: () => import('./views/antifraud/tables/BlackList'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-cancel',
                    title: 'Антифрод'
                },
            }, {
                path: '/(uk/|ru/)?/anti-fraud/exeption-drivers',
                name: 'ExiptionDrivers',
                component: () => import('./views/antifraud/tables/ExiptionDrivers'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-cancel',
                    title: 'Антифрод'
                },
            }, 
        ]
    }, {
        path: '/(uk/|ru/)?/anti-fraud/security',
        component: () => import('./views/antifraud/Security'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-cancel',
            title: 'Антифрод Глобальная настройка'
        }
    }, {
        path: '/(uk/|ru/)?/anti-fraud/devices',
        component: () => import('./views/antifraud/Device'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-cancel',
            title: 'Идентификаторы устройств'
        }
    }, {
        path: '/(uk/|ru/)?/promotions/index',
        component: () => import('./views/promotions/Index'),
        name: 'PromotionsList',
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-discount',
            title:'Акции'
        },
    }, {
        path: '/(uk/|ru/)?/promotions/edit',
        component: () => import('./views/promotions/Edit'),
        //name: 'PromotionEdit',
        altNavSubMenuActive:'/promotions/index',
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-discount',
        },
        children: [
            {
                path: '',
                name: 'PromotionEditMain',
                component: () => import('./views/promotions/modules/Main'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    title:'Акции'
                },
            }, {
                path: '/(uk/|ru/)?/promotions/work-rules',
                name: 'PromotionWorkRules',
                component: () => import('./views/promotions/modules/WorkRules'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    title:'Акции'
                },
            }, {
                path: '/(uk/|ru/)?/promotions/raffle-coupon',
                name: 'PromotionRaffleCoupon',
                component: () => import('./views/promotions/modules/RaffleCoupon'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    title:'Акции'
                },
            }, {
                path: '/(uk/|ru/)?/promotions/raffle-ddriver',
                name: 'PromotionRaffleDriver',
                component: () => import('./views/promotions/modules/RaffleDriver'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    title:'Акции'
                },
            }, {
                path: '/(uk/|ru/)?/promotions/reports/transactions',
                name: 'PromotionCompensationTransaction',
                component: () => import('./views/promotions/modules/Transactions'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    title:'Акции'
                },
            }, {
                path: '/(uk/|ru/)?/promotions/reports/drivers',
                name: 'PromotionCompensationDrivers',
                component: () => import('./views/promotions/modules/PromotionCompensationDrivers'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    title:'Акции'
                },
            }, {
                path: '/(uk/|ru/)?/promotions/drivers/participate-history',
                name: 'PromotionPayHistory',
                component: () => import('./views/promotions/modules/PromotionPayHistory'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    title:'Акции'
                },
            }, {
                path: '/(uk/|ru/)?/promotions/drivers/participate-history',
                name: 'PromotionParticipateHistory',
                component: () => import('./views/promotions/modules/PromotionParticipateHistory'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    title:'Акции'
                },
            }, {
                path: '/(uk/|ru/)?/promotions/drivers/import',
                name: 'PromotionAddDrivers',
                component: () => import('./views/promotions/modules/PromotionAddDrivers'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    title:'Акции'
                },
            }, {
                path: '/(uk/|ru/)?/promotions/drivers',
                name: 'PromotionDriver',
                component: () => import('./views/promotions/modules/Driver'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    title:'Акции'
                },
            }, 
        ]
    }, {
        path: '/(uk/|ru/)?/push-notification/list',
        name: 'PushList',
        component: () => import('./views/push/List.vue'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-discount',
            title: 'Рассылки уведомлений'
        },
    }, {
        path: '/(uk/|ru/)?/push-notification/create',
        name: 'PushCreate',
        component: () => import('./views/push/Create.vue'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-discount',
            title: 'Создать рассылку',
            altNavSubMenuActive:'/push-notification/list',
        },
    }, {
        path: '/(uk/|ru/)?/promocodes/index',
        name: 'Promocodes',
        component: () => import('./views/promocodes/Promo'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-discount',
            title: 'Промокоды'
        }
    }, {
        path: '/(uk/|ru/)?/polls/index',
        name: 'polls',
        component: () => import('./views/polls/index'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-discount',
            title: 'Опросы'
        }
    }, {
        path: '/(uk/|ru/)?/transactions/bonus',
        name: 'TransactionsBonus',
        component: () => import('./views/transactions/bonus'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-discount',
            title: 'Список бонусов'
        }
    }, {
        path: '/(uk/|ru/)?/polls/view',
        name: 'pollsview',
        component: () => import('./views/polls/view'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-discount',
            title: 'Опросы'
        }
    }, {
        path: '/(uk/|ru/)?/polls/edit',
        name: 'PollEdit',
        component: () => import('./views/polls/edit'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-discount',
            title: 'Опросы'
        }
    },
    // {
    //     path: '/(uk/|ru/)?/sms-conversion/index',
    //     name: 'smsList',
    //     component: () => import('./views/conversion/Smslist'),
    //     query: true,
    //     meta: {
    //         requiresAuth: true,
    //         activeSideIcon:'#icon-discount',
    //         title: 'Сопровождение конверсии'
    //     }
    // },
    {
        path: '/(uk/|ru/)?/sms-conversio/create',
        name: 'SmsCreate',
        component: () => import('./views/conversion/SmsCreate'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-discount',
            title: 'Смс-конверсия',
            altNavSubMenuActive:'/sms-conversion-event/index',
        }
    }, {
        path: '/(uk/|ru/)?/sms-conversion-event/index',
        name: 'EventsList',
        component: () => import('./views/conversion/EventsList'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-discount',
            title: 'Смс-события',
            altNavSubMenuActive:'/sms-conversion-event/index',
        }
    }, {
        path: '/(uk/|ru/)?/sms-conversion/events/edit',
        name: 'EventEdit',
        component: () => import('./views/conversion/EventEdit'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-discount',
            title: 'Смс-события'
        }
    }, {
        path: '/(uk/|ru/)?/promocodes/detail',
        name: 'PromocodeDetaild',
        component: () => import('./views/promocodes/Detail'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-discount',
            title: 'Промокоды'
        }
    }, {
        path: '/(uk/|ru/)?/promocodes/edit',
        name: 'PromocodesEdit',
        component: () => import('./views/promocodes/Edit'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-discount',
            title: 'Промокоды'
        }
    }, {
        path: '/(uk/|ru/)?/baiga/index',
        component: () => import('./views/baiga/Index'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-discount',
        },
        children: [
            {
                path: '',
                name: 'BaigaSettings',
                component: () => import('./views/baiga/Settings'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    title:'Байга'
                },
            }, {
                path: '/(uk/|ru/)?/baiga/Results',
                name: 'BaigaResults',
                component: () => import('./views/baiga/Results'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    title:'Байга'
                },
            }, {
                path: '/(uk/|ru/)?/baiga/publish',
                name: 'BaigaPublish',
                component: () => import('./views/baiga/Publish'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    title:'Байга'
                },
            }, 
        ]
    }, {
        path: '/(uk/|ru/)?/baiga/edit',
        name: 'SettingsForm',
        component: () => import('./views/baiga/SettingsForm'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-discount',
            title: 'Байга'
        }
    }, {
        path: '/(uk/|ru/)?/teams/index',
        component: () => import('./views/teams/Teams'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-discount',
            title: 'Список бригад'
        },
        children: [
            {
                path: '',
                name: 'TeamsList', // Имя дочернего маршрута по умолчанию
                component: () => import('./views/teams/tables/TeamList'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    altNavSubMenuActive:'/teams/index',
                    title: 'Бригады',
                },
            }, {
                path: '/(uk/|ru/)?/teams/top',
                name: 'TeamTop',
                component: () => import('./views/teams/tables/TeamTop'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    altNavSubMenuActive:'/teams/index',
                    title: 'Бригады',
                },
            }
        ]
    }, {
        path: '/(uk/|ru/)?/teams/users',
        component: () => import('./views/teams/TeamEdit'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-discount',
            altNavSubMenuActive:'/teams/index',
        },
        children: [
            {
                path: '',
                name: 'TeamUsers',
                component: () => import('./views/teams/tables/TeamUsers'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    altNavSubMenuActive:'/teams/index',
                    title: 'Бригады',
                },
            }, {
                path: '/(uk/|ru/)?/teams/history',
                name: 'TeamHistory',
                component: () => import('./views/teams/tables/TeamHistory'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    altNavSubMenuActive:'/teams/index',
                    title: 'Бригады',
                },
                 
            },  {
                path: '/(uk/|ru/)?/teams/history/detail',
                name: 'TeamHistoryDetail',
                component: () => import('./views/teams/tables/TeamHistoryDetail'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    altNavSubMenuActive:'/teams/index',
                    title: 'Бригады',
                },
            }, {
                path: '/(uk/|ru/)?/teams/balance',
                name: 'TeamHistoryBalance',
                component: () => import('./views/teams/tables/TeamHistoryBalance'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    altNavSubMenuActive:'/teams/index',
                    title: 'Бригады',
                },
            },  {
                path: '/(uk/|ru/)?/teams/payRequest',
                name: 'TeamPayRequest',
                component: () => import('./views/teams/tables/TeamPayRequest'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    altNavSubMenuActive:'/teams/index',
                    title: 'Бригады',
                },
            },  {
                path: '/(uk/|ru/)?/teams/candidates',
                name: 'TeamCandidates',
                component: () => import('./views/teams/tables/TeamCandidates'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    altNavSubMenuActive:'/teams/index',
                    title: 'Бригады',
                },
            },  {
                path: '/(uk/|ru/)?/teams/summary',
                name: 'TeamSummary',
                component: () => import('./views/teams/tables/TeamSummary'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-discount',
                    altNavSubMenuActive:'/teams/index',
                    title: 'Бригады',
                },
            },
        ]
    }, {
        path: '/(uk/|ru/)?/sms/index',
        name: 'SMSlist',
        component: () => import('./views/sms/SMSlist'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-mobile',
            title: 'Список СМС'
        }
    }, {
        path: '/(uk/|ru/)?/dumps/statistic-dumps',
        
        component: () => import('./views/dumps/index'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-dumps',
            title: 'Управление'
        },
        children: [
            {
                path: '',
                name: 'dumpsComplete',
                component: () => import('./views/dumps/tables/complete'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-dumps',
                    title: 'Обработанные'
                },
                children: [
                    {
                        path: '',
                        name: 'completePhotocontrol',
                        component: () => import('./views/dumps/tables/completePhotocontrol'),
                        query: true,
                        props: true,
                        meta: {
                            requiresAuth: true,
                            activeSideIcon:'#icon-dumps',
                            title: 'Завершение регистрации'
                        },
                    },
                    {
                        path: '/(uk/|ru/)?/dumps/statistic-dumps/lead/complete',
                        name: 'dumpsCompleteList',
                        component: () => import('./views/dumps/tables/completeTable'),
                        query: true,
                        props: true,
                        meta: {
                            requiresAuth: true,
                            activeSideIcon:'#icon-dumps',
                            title: 'Завершение регистрации'
                        },
                    }, {
                        path: '/(uk/|ru/)?/dumps/statistic-dumps/lead',
                        name: 'dumpscompleteLead',
                        component: () => import('./views/dumps/tables/completeLead'),
                        query: true,
                        props: true,
                        meta: {
                            requiresAuth: true,
                            activeSideIcon:'#icon-dumps',
                            title: 'Возврат неактивных'
                        },
                    }, {
                        path: '/(uk/|ru/)?/dumps/statistic-dumps/repeat',
                        name: 'dumpscompleteRepeat',
                        component: () => import('./views/dumps/tables/completeRepeat'),
                        query: true,
                        props: true,
                        meta: {
                            requiresAuth: true,
                            activeSideIcon:'#icon-dumps',
                            title: 'Обзвон'
                        },
                    },
                ]
            },
            {
                path: '/(uk/|ru/)?/dumps/statistic-dumps/statistic',
                name: 'dumpsUnComplete',
                component: () => import('./views/dumps/tables/UnComplete'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-dumps',
                    title: 'Статистика'
                },
            },
        ]
    }, {
        path: '/(uk/|ru/)?/dumps/statistic-dumps/statistic/operator',
        name: 'Operator',
        component: () => import('./views/dumps/tables/Operator'),
        query: true,
        props: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-dumps',
            title: 'Оператор'
        },
    }, {
        path: '',
        name: 'DumpsCalling',
        component: () => import('./views/dumps/calling'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-dumps',
            title: 'Обзвон'
        },
        children: [
            {
                path: '/(uk/|ru/)?/call-dumps/calling/registered',
                name: 'DumpsNew',
                component: () => import('./views/dumps/tables/DumpsNew'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-dumps',
                    title: 'Новые'
                },
            }, 
            {
                path: '/(uk/|ru/)?/call-dumps/calling/registered',
                name: 'DumpsInWork',
                component: () => import('./views/dumps/tables/DumpsInWork'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-dumps',
                    title: 'В работе'
                },
            }, 
            {
                path: '/(uk/|ru/)?/call-dumps/calling/registered',
                name: 'DumpsFinished',
                component: () => import('./views/dumps/tables/DumpsFinished'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-dumps',
                    title: 'Завершенные'
                },
            }, 
            {
                path: '/(uk/|ru/)?/call-dumps/calling/registered',
                name: 'DumpsRegistered',
                component: () => import('./views/dumps/tables/DumpsRegistered'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-dumps',
                    title: 'Зарегистрированные'
                },
            }, {
                path: '/(uk/|ru/)?/call-dumps/calling',
                name: 'DumpsPhotocontrol',
                component: () => import('./views/dumps/tables/DumpsPhotocontrol'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-dumps',
                    title: 'Фотоконтроль'
                },
            }, 
            {
                path: '/(uk/|ru/)?/call-dumps/calling/complete',
                name: 'CallingComplete',
                component: () => import('./views/dumps/tables/CallingComplete'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-dumps',
                    title: 'Завершение регистрации'
                },
            }, {
                path: '/(uk/|ru/)?/call-dumps/calling/repeat',
                name: 'CallingRepeat',
                component: () => import('./views/dumps/tables/CallingRepeat'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-dumps',
                    title: 'Возврат неактивных'
                },
            },  {
                path: '/(uk/|ru/)?/call-dumps/calling/lead',
                name: 'CallingLead',
                component: () => import('./views/dumps/tables/CallingLead'),
                query: true,
                props: true,
                meta: {
                    requiresAuth: true,
                    activeSideIcon:'#icon-dumps',
                    title: 'Подумают'
                },
            },
        ]

    }, {
        path: '/(uk/|ru/)?/dumps/calling/driver',
        name: 'DumpsDriver',
        component: () => import('./views/dumps/Driver'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-dumps',
            title: 'Водитель'
        },
    }, {
        path: '/(uk/|ru/)?/self-emloyment/status-history',
        name: 'selfemploymentIndex',
        component: () => import('./views/selfemployment/index'),
        query: true,
        meta: {
            requiresAuth: true,
           // activeSideIcon:'#icon-dumps',
            title: 'История статусов'
        },
    }, {
        path: '/(uk/|ru/)?/self-emloyment/without-employment',
        name: 'selfemploymentDrivers',
        component: () => import('./views/selfemployment/drivers'),
        query: true,
        meta: {
            requiresAuth: true,
          //  activeSideIcon:'#icon-dumps',
            title: 'Без самозанятости'
        },
    }, {
        path: '/(uk/|ru/)?/self-emloyment/option',
        name: 'selfemploymentReference',
        component: () => import('./views/selfemployment/reference'),
        query: true,
        meta: {
            requiresAuth: true,
          //  activeSideIcon:'#icon-dumps',
            title: 'Самозанятость Настройки'
        },
    }, {
        path: '/(uk/|ru/)?/car/index',
        name: 'ListAuto',
        component: () => import('./views/rentauto/ListAuto.vue'),
        query: true,
        meta: {
            requiresAuth: true,
          //  activeSideIcon:'#icon-dumps',
            title: 'Список авто'
        },
    }, {
        path: '/(uk/|ru/)?/car/all-car',
        name: 'ListAllCar',
        component: () => import('./views/rentauto/ListAllCar.vue'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-arrow-right',
            title: 'Список всех авто'
        },
    }, {
        path: '/(uk/|ru/)?/car/create',
        name: 'createAuto',
        component: () => import('./views/rentauto/modules/create.vue'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-rent-car',
            title: 'Список авто',
            altNavSubMenuActive:'/car/index',
        },
    }, {
        path: '/(uk/|ru/)?/car/view',
        name: 'viewAuto',
        component: () => import('./views/rentauto/modules/view.vue'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-rent-car',
            title: 'Просмотр авто',
            altNavSubMenuActive:'/car/all-car',
        },
    }, {
        path: '/(uk/|ru/)?/request/index',
        name: 'ListRent',
        component: () => import('./views/rentauto/ListRent.vue'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-rent-car',
            title: 'Аренда Авто',
        },
    }, {
        path: '',
        name: 'task-pool',
        component: () => import('./views/financial/TaskPool.vue'),
        query: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-financial',
            altNavSubMenuActive:'/financial/task-pool',
        },
        children: [ {
            path: '/(uk/|ru/)?/financial/task-pool',
            name: 'Task',
            component: () => import('./views/financial/Task'),
            query: true,
            props: true,
            meta: {
                requiresAuth: true,
                activeSideIcon:'#icon-financial',
                title: 'Финансовый мониторинг'
            },
        }, {
            path: '/(uk/|ru/)?/financial/history',
            name: 'TaskHistory',
            component: () => import('./views/financial/History'),
            query: true,
            props: true,
            meta: {
                requiresAuth: true,
                activeSideIcon:'#icon-financial',
                title: 'Финансовый мониторинг',
            },
        },]
    }, {
        path: '/(uk/|ru/)?/individual-entrepreneurship/list',
        name: 'ie_List',
        component: () => import('./views/individual-entrepreneurship/list.vue'),
        query: true,
        props: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-ie',
            title: 'Список ИП',
        },
    },  {
        path: '/(uk/|ru/)?/individual-entrepreneurship/create',
        name: 'ie_create',
        component: () => import('./views/individual-entrepreneurship/create.vue'),
        query: true,
        props: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-ie',
            title: 'Создание нового ИП',
        },
    },  {
        path: '',
        name: 'ie_edit_form',
        component: () => import('./views/individual-entrepreneurship/Edit.vue'),
        query: true,
        props: true,
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-ie',
            title: 'Создание нового ИП',
        },
        children: [ {
            path: '/(uk/|ru/)?/individual-entrepreneurship/edit',
            name: 'ie_edit',
            component: () => import('./views/individual-entrepreneurship/tab_edit.vue'),
            meta: {
                requiresAuth: true,
                activeSideIcon:'#icon-ie',
                title: 'Карточка ИП',
            },
        }, {
            path: '/(uk/|ru/)?/individual-entrepreneurship/edit/driver',
            name: 'ie_subDriver',
            component: () => import('./views/individual-entrepreneurship/driver.vue'),
            meta: {
                requiresAuth: true,
                activeSideIcon:'#icon-ie',
                title: 'Карточка ИП',
            },
        }]
    },  {
        path: '',
        name: 'nca',
        component: () => import('./views/NCALayer/index.vue'),
        query: true,
        props: true,
        meta: {
            requiresAuth: true,
            //activeSideIcon:'#icon-ie',
            title: 'NACLayer',
        },
        children: [{
            path: '/(uk/|ru/)?/ie/docs',
            name: 'ie_docs',
            component: () => import('./views/NCALayer/tables/docs.vue'),
            meta: {
                requiresAuth: true,
                activeSideIcon:'#icon-ie',
                title: 'Карточка ИП',
            }, 
        },]
    }, {
        path: '/(uk/|ru/)?/ie/sign',
        name: 'ie_signdocs',
        component: () => import('./views/NCALayer/signDoc.vue'),
        meta: {
            requiresAuth: true,
            activeSideIcon:'#icon-ie',
            title: 'Подпись документов',
        }, 
    }
    // {
    //     path: '/(uk/|ru/)?/ie/candidates',
    //     name: 'ie_candidates',
    //     component: () => import('./views/ie/candidates/Index'),
    //     query: true,
    //     props: true,
    //     meta: {
    //         requiresAuth: true,
    //         activeSideIcon:'#icon-ie',
    //         title: 'Заявки на подключение ИП',
    //     },
    // }, {
    //     path: '/(uk/|ru/)?/ie/candidates/edit',
    //     name: 'ie_candidate_edit',
    //     component: () => import('./views/ie/candidates/edit'),
    //     query: true,
    //     props: true,
    //     meta: {
    //         requiresAuth: true,
    //         activeSideIcon:'#icon-ie',
    //         title: 'Заявки на подключение ИП',
    //     },
    // }, {
    //     path: '/(uk/|ru/)?/ie/default',
    //     name: 'ip_list',
    //     component: () => import('./views/ie/iplist/index'),
    //     query: true,
    //     props: true,
    //     meta: {
    //         requiresAuth: true,
    //         activeSideIcon:'#icon-ie',
    //         title: 'Список ИП',
    //     },
    // }, {
    //     path: '/(uk/|ru/)?/ie/default/edit',
    //     name: 'ip_edit',
    //     component: () => import('./views/ie/iplist/edit'),
    //     query: true,
    //     props: true,
    //     meta: {
    //         requiresAuth: true,
    //         activeSideIcon:'#icon-ie',
    //         title: 'Карта ИП',
    //     },
    // }, {
    //     path: '/(uk/|ru/)?/ie/archive',
    //     name: 'ip_archive',
    //     component: () => import('./views/ie/archive/index'),
    //     query: true,
    //     props: true,
    //     meta: {
    //         requiresAuth: true,
    //         activeSideIcon:'#icon-ie',
    //         title: 'Незавершенные ИП',
    //     },
    // }, {
    //     path: '/(uk/|ru/)?/ie/archive/view',
    //     name: 'ip_archive_view',
    //     component: () => import('./views/ie/archive/view'),
    //     query: true,
    //     props: true,
    //     meta: {
    //         requiresAuth: true,
    //         activeSideIcon:'#icon-ie',
    //         title: 'Незавершенный ИП',
    //     },
    // }, {
    //     path: '/(uk/|ru/)?/ie/dismissal',
    //     name: 'ip_archive',
    //     component: () => import('./views/ie/dismissal/index'),
    //     query: true,
    //     props: true,
    //     meta: {
    //         requiresAuth: true,
    //         activeSideIcon:'#icon-ie',
    //         title: 'Незавершенные ИП',
    //     },
    // },
]
 

export default new Router({
    mode: 'history',
    base: '/',
    routes
}) 
