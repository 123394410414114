import req from './request'

const DEFAULT_ENDPOINTS = {
    dashboard: '/dashboard',
     
}

export default {
    dashboard(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.dashboard, param)
    },
}