
import req from './request'

const DEFAULT_ENDPOINTS = {
    default: '/ie',
    reference: '/ie/reference',
    drivers: '/ie/drivers',
    driversView: '/ie/drivers/view',
    driversEvents: '/ie/drivers/events',
    driversDocuments: '/ie/drivers/documents',
    documentsAuth: '/ie/documents/auth',
    documents: '/ie/documents',
    archive: '/ie/documents/archive',
    signDocuments:'/ie/documents/sign-documents',


    // old
    candidates: '/ie/candidates',
    view: '/ie/candidates/view',
    balances: '/ie/candidates/balances',
    //reference: '/ie/candidates/reference',
    parks: '/ie/default/parks',
    defaultView: '/ie/default/view',
    defaultReference: '/ie/default/reference',
    defaultApprove: '/ie/default/approve',
    defaultDecline: '/ie/default/decline',
    defaultArchive: '/ie/default/archive',
    archivePark: '/ie/archive/park',
    archiveBtl: '/ie/archive/back-to-list',
    archiveReference: '/ie/archive/reference',
    dismissals: '/ie/dismissals',
}

export default {
    
    default(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.default, param)
    },
    reference(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reference, param)
    },
    drivers(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.drivers, param)
    },
    driversView(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.driversView, param)
    },
    driversEvents(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.driversEvents, param)
    },
    driversDocuments(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.driversDocuments, param)
    },
    documentsAuth(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.documentsAuth, param)
    },
    documents(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.documents, param)
    },
    archive(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.archive, param)
    },
    signDocuments(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.signDocuments, param)
    },


    // old

    candidates(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.candidates, param)
    },
    view(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.view, param)
    },
    balances(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.balances, param)
    },
    parks(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.parks, param)
    },
    defaultView(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.defaultView, param)
    },
    defaultReference(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.defaultReference, param)
    },
    defaultApprove(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.defaultApprove, param)
    },
    defaultDecline(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.defaultDecline, param)
    },
    defaultArchive(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.defaultArchive, param)
    },
  
    archivePark(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.archivePark, param)
    },
    archiveBtl(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.archiveBtl, param)
    },
    archiveReference(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.archiveReference, param)
    },
    default(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.default, param)
    },
    dismissals(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.dismissals, param)
    },

    

}